import { axiosAuth } from "@/utils/newAxios";
import { axios } from "../utils/axios";

export async function getUser() {
  let data = await axios.get("/api/v2/user/myProfile");
  return data.data?.user;
}

export async function getUserInfo(userId) {
  try {
    let res = await axiosAuth.get(`/api/v2/user/${userId}`);
    return res;
  } catch (err: unknown) {
    throw err;
  }
}

export async function getUserByReferralCode(referralCode) {
  try {
    let res = await axiosAuth.get(`/api/v2/user/referral/${referralCode}`).catch((error) => {
      console.log(error)
    });

    return res;
  } catch (err: unknown) {
    throw err;
  }
}

export async function getReferrals() {
  try {
    let res = await axiosAuth.get(`/api/v2/user/referral`)
    return res.data;
  } catch (err: unknown) {
    throw err;
  }
}
